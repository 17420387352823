<template>
  <div class='main has-header has-footer'>
    <mt-header fixed title="协议模版">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <img v-for="(item,index) of list" :src="item" :key="index" alt="">
  </div>
</template>

<script>
  import {
    getUserSigningTpl
  } from '@api/tasks'
  export default {
    props: ['id'],
    data() {
      return {
        list:[]
      }
    },
    mounted() {
      let data = {
        id: this.id
      }
      getUserSigningTpl(data).then((res) => {
        if (res.code === 200) {
          let list = res.data
          list.splice(0,1)
          this.list = [...list]
        }
      })
    },
    methods: {
    }
  }
</script>

<style lang='less' scoped>
</style>